<template>
  <div>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" lg="8">
          <Breadcrumbs :active-tab="0"/>
        </v-col>
        <v-col cols="12" lg="8">

          <v-alert :value="isError" dismissible prominent text type="error">
            <ul>
              <li v-for="(error,i) in errors" :key="i">{{ error[0] }}</li>
            </ul>
          </v-alert>

          <v-card class="rounded-lg shadow">
            <v-card-text class="pa-10">

              <v-row>
                <v-col>
                  <v-text-field v-model.trim="campaign.name" label="Nom *"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model.trim="campaign.name_ar"
                                class="ar rtl"
                                label="الإ سم *"></v-text-field>
                </v-col>
              </v-row>

              <v-menu ref="menu"
                      v-model="menu"
                      :close-on-click="false"
                      :close-on-content-click="false"
                      min-width="auto"
                      offset-y
                      transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :value="campaign.dates.join(' ~ ')"
                                append-icon="mdi-calendar"
                                label="Planification *" readonly
                                v-bind="attrs" v-on="on">
                  </v-text-field>
                </template>
                <v-date-picker
                        v-model="campaign.dates"
                        :min="date_now" range scrollable>
                  <v-spacer></v-spacer>
                  <v-btn class="rounded-lg" text
                         @click="menu = false">
                    <v-icon left>mdi-close-circle-outline</v-icon>
                    Fermer
                  </v-btn>
                  <v-btn :disabled="campaign.dates.length < 2" class="rounded-lg"
                         color="primary"
                         text
                         @click="menu = false">
                    <v-icon left>mdi-check-circle-outline</v-icon>
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-file-input :clearable="false"
                            :disabled="upload_loading"
                            :error-messages="upload_errors.photo"
                            :loading="upload_loading"
                            accept="image/png,image/jpeg"
                            append-icon="mdi-image-outline"
                            chips label="Photo"
                            show-size
                            small-chips
                            @change="uploadPhoto($event)"
                            @click="upload_errors && upload_errors.photo ? upload_errors.photo = '' : false"></v-file-input>

              <div v-if="campaign.photo"
                   :class="$vuetify.theme.dark ? 'grey' : 'grey lighten-3'"
                   class="d-flex justify-space-between pa-3 rounded"
                   style="margin-top:-10px">
                <v-avatar size="40"
                          @click="[image = [{src: FILE_URL + campaign.photo}],visible = true]">
                  <v-img :src="FILE_URL + campaign.photo" class="rounded cursor-pointer"/>
                </v-avatar>
                <v-spacer/>
                <v-icon color="success">mdi-check-circle</v-icon>
              </div>


              <v-textarea v-model.trim="campaign.description"
                          label="Description"
                          rows="2"></v-textarea>

              <v-textarea v-model.trim="campaign.description_ar"
                          class="ar rtl"
                          label="* الوصف"
                          rows="2"></v-textarea>

            </v-card-text>
          </v-card>
          <div class="d-flex justify-space-between mt-3">
            <v-btn class="rounded-lg" color="primary"
                   text
                   @click="$router.push('/campaigns')">
              <v-icon color="primary" left>mdi-arrow-left</v-icon>
              Retour
            </v-btn>
            <v-spacer/>
            <v-btn :loading="isLoading"
                   class="rounded-lg"
                   color="primary"
                   depressed
                   @click="validate()">
              Suivant
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <!--Dialogs-->
      <vue-easy-lightbox :imgs="image"
                         :index="index"
                         :visible="visible"
                         escDisabled
                         moveDisabled
                         @hide="visible = false"></vue-easy-lightbox>
    </v-container>
  </div>
</template>

<script>
import keywords from '../../../keywords.json'
import {HTTP} from "@/http-common";
import Breadcrumbs from "./components/Breadcrumbs";

export default {
    components: {Breadcrumbs},
    data() {
        return {
            keywords,
            date_now: this.$moment().format('YYYY-MM-DD'),
            menu: false,

            //Upload image
            upload_loading: false,
            upload_errors: [],

            //validations
            isError: false,
            isLoading: false,
            errors: '',

            //Show image
            image: '',
            visible: false,
            index: 0,
            FILE_URL: process.env.VUE_APP_FILE_URL,
        }
    },
    methods: {
        validate() {
            this.isLoading = true
            this.isError = false
            this.$Progress.start()
            let data = {
                name: this.campaign.name,
                name_ar: this.campaign.name_ar,
                description: this.campaign.description,
                description_ar: this.campaign.description_ar,
                dates: this.campaign.dates,
            }
            HTTP.post('/campaigns/validate/info-planning', {
                campaign: data
            }).then(() => {
                this.isLoading = false
                this.isError = false
                this.$Progress.finish()
                this.$store.commit('stepper1', true)
                this.$router.push('/campaigns/stepper/tasks')
            }).catch(err => {
                this.isLoading = false
                this.isError = true
                this.$Progress.fail()
                this.$store.commit('stepper1', false)
                this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
                this.errors = err.response.data.errors
                this.$vuetify.goTo(0)
                console.log(err)
            })
        },
        uploadPhoto(file) {
            this.upload_loading = true
            this.upload_errors = []
            let formData = new FormData()
            formData.append('photo', file)
            HTTP.post('/campaigns/upload-image', formData).then((res) => {
                this.upload_loading = false
                this.campaign.photo = res.data.file_name
            }).catch(err => {
                this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
                this.upload_errors = err.response.data.errors
                this.upload_loading = false
                console.log(err)
            })
        },
    },
    computed: {
        campaign() {
            return this.$store.state.campaign
        }
    }
}
</script>

<style>

</style>