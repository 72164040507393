<template>
  <div>
    <v-toolbar class="rounded-lg transparent" flat>
      <v-tabs v-model="tab" class="pointer-events-none">
        <v-tab class="b-700">
          <v-icon left>mdi-calendar-outline</v-icon>
          Info & Planification
        </v-tab>
        <v-tab class="b-700">
          <v-icon left>mdi-file-document-outline</v-icon>
          Tâches
        </v-tab>
      </v-tabs>
      <v-spacer/>
      <v-btn depressed rounded color="primary"
             class="mt-3"
             @click="$router.push('/campaigns')">
        <v-icon left>mdi-arrow-left</v-icon>
        Retour
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  props: ['activeTab'],
  methods: {
    changeTab(e) {
      this.$emit('update:activeTab', e)
    }
  },
  computed: {
    tab: {
      get: function () {
        return this.activeTab;
      },
      set: function (newValue) {
        this.$emit('update:activeTab', newValue)
      }
    },
  }
}
</script>
<style scoped>
</style>
